
    <div class="inner_page"><section class="inner_hero_sec">
      <div class="container position-relative h-100">
        <div class="inner_hero_content">
          <h1>Cloud Services</h1>
          <ol class="breadcrumb pl-0">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item active">Cloud Services</li>
          </ol>
        </div>
      </div>
    </section>
    <div class="page_wrapper">
     <!-- <section class="two_columne">
        <div class="container">
          <div class="row v-center align-items-center">
            <div class="col-md-12 col-lg-5 mt-30">
              <div class="img-port"> <img class="img-fluid" src="/assets/images/1.jpg" alt="a"> </div>
            </div>
            <div class="col-md-12 col-lg-6  offset-lg-1">
              <h3 class="mt-20" ><span class="theme-color">Cloud Services</span>  - Web & Mobilesoftware design and development. SaaS solutions cloud native product manager </h3>
            </div>
          </div>
        </div>
      </section> -->
      <section class="ponter_sec">
        <div class="container">
       <div class="row align-items-center">   <div class="col-lg-7">
        <h3 class="mt-20" ><span class="theme-color">Cloud and On-Premise Architecture and Conulting Services</span></h3>
        <br>
            <!--<div class="pointer_title"> <strong>A typical services engagement with <a href="https://www.nodeasset.com">NodeAsset.com</a> will involve the following teams: </strong> </div>-->
            <ul id="navigation">
              <li> <a id="1" href="/saas-product-design-and-development" data-filename="mk-13"><strong>Product Design & Development</strong>
                <p>Web & Mobile software design and development. SaaS solutions cloud native product manager</p></a> 
              </li>
              <li> <a id="2" href="/managed-cloud-operations" data-filename="mk-14"><strong>Managed Cloud Operations</strong>
                <p>The Web Design team creates beautiful layouts that grab the attention of the user on all mobile and web devices. </p></a>
              </li>
              <li> <a id="3" href="/workflow-automation" data-filename="mk-15"><strong>Workflow Automation</strong>
                <p>Manual process automation with Artificial intelligence (AI) and Machine Learning (ML) for business productivity applications.  </p></a>
              </li>
              <li> <a id="4" href="/legacy-modernization" data-filename="mk-16"><strong>Legacy Modernization</strong>
                <p>Migrating existing systems to cloud native architecture. </p></a>
              </li>
            </ul>
          </div>
          <div class="col-md-5 d-none d-lg-block">
              <div class="box" >
      <img src="/assets/mk-13.jpg" alt="Pointer Thumbnail"/>                    
    </div>
            <div class="pointer-image"  id="bg">
                
          
            </div>
          </div>
        </div></div>
      </section>
            <section class="tested_para">
        <div class="container">
            
            <p>Project deliverables are reviewed by the following team to ensure that all aspects of deploying applications into production have been accounted for. </p>
            <span class="tested_thumb"><img src="/assets/images/test.svg" alt="texted thumb"/></span>
            </div>
             
        </section>
      <section class="tech_logo">
        <div class="container">
          <div class="logo_group"> <span><img src="/assets/images/tec-logo/angular.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/c-sharp.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/docker.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/java.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/Kubernetes.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/net.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/node.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/react.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/type.png" alt="Technology Logo"/></span> <span><img src="/assets/images/tec-logo/veu.png" alt="Technology Logo"/></span> </div>
        </div>
      </section>
      </div>
      
    