import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  title = 'NodeAsset Crypto Currency Investment Products';
  keywords = 'blockchain, cryptocurrency, asset accounts, ethereum, bitcoin, dogecoin, hyperledger, ICO, Initial Coin Offering, Kubernetes, Smart Contracts, Digital Wallet';
  description = 'NodeAsset crypto-currency asset accounts hold the tokenized value of physical assets like US dollars or gold in blockchain based accounts. NAHC investors are eligible to open digital asset accounts corresponding to the current basket of assets that make up the NAHC.';
  sitename = 'nodeasset.com';
  url = 'https://www.nodeasset.com/investors';
  image = 'https://www.nodeasset.com/assets/images/web-image.png';
  
  constructor(private metaTagService: Meta, private titleService: Title,) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description}
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: this.keywords}
    );
    this.metaTagService.updateTag(
      { property: 'og:site_name', content: this.sitename}
    );
    this.metaTagService.updateTag(
      { property: 'og:url', content: this.url}
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description}
    );
    this.metaTagService.updateTag(
      { property: 'og:image', content: this.image}
    );
    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );
  
  }

}
