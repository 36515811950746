import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-private-exchange',
  templateUrl: './private-exchange.component.html',
  styleUrls: ['./private-exchange.component.css']
})
export class PrivateExchangeComponent implements OnInit {
  title = 'Private Blockchain Cryptocurrency exchange';
  keywords = 'blockchain, cryptocurrency, private, exchange, erc20 ethereum, bitcoin, dogecoin, hyperledger, cloud, kubernetes, smart contract, digital wallet';
  description = 'NodeAsset Holdings Coin (NAHC) is a crypto-currency created by NodeAsset, LLC to facilitate investment, replacing the traditional approach of creating company stock and then placing that stock on a public exchange like NASDAQ or New York Stock Exchange (NYSE).';
  sitename = 'nodeasset.com';
  url = 'https://www.nodeasset.com/private-blockchain-exchange';
  image = 'https://www.nodeasset.com/assets/images/web-image.png';
  constructor(private metaTagService: Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description}
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: this.keywords}
    );
    this.metaTagService.updateTag(
      { property: 'og:site_name', content: this.sitename}
    );
    this.metaTagService.updateTag(
      { property: 'og:url', content: this.url}
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description}
    );
    this.metaTagService.updateTag(
      { property: 'og:image', content: this.image}
    );
    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );
  }

}
