import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Georgetown Data';
  color = 'white';
  color1 = 'black';
  wdisplay = 'block';
  ddisplay = 'none';

  constructor(location: Location, router: Router, private metaTagService: Meta, private titleService: Title,) { 
    router.events.subscribe(val => {
      if (location.path() == "/investors") {
        this.color = this.color1;
        this.wdisplay = 'none';
        this.ddisplay = 'block';
      } else if (location.path() == "/investment-product") {
        this.color = this.color1;
        this.wdisplay = 'none';
        this.ddisplay = 'block';
      } 
      console.log(location.path().toString());
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

  
  }

}
