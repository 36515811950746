
<!--<app-header></app-header>-->
<header>
        <nav id="main_nav" class="navbar fixed-top  navbar-expand-lg ">
          <div class="container"> 
              
                <div id="logo1" [ngStyle]="{'display':color === 'white' ? 'block' : 'none' }" >
                  <a class="navbar-brand" href="/"><img src="/assets/images/GDS-Logo/Logo Files/For Web/png/White logo - no background.png" width="170"  class="w-logo" alt="NodeAsset Logo"/> <img src="/assets/images/GDS-Logo/Logo Files/For Web/png/Black logo - no background.png" width="170" class="d-logo d-non" alt="NodeAsset Logo"/> </a>
                </div>
                
                <div id="logo2" [ngStyle]="{'display':color === 'black' ? 'block' : 'none' }" >
                  <a class="navbar-brand" style="color:black" href="/"><img src="/assets/images/d-logo-a.png" width="170" class="d-non" alt="NodeAsset Logo"/> </a>
                </div>
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"><i class="fa fa-bars"></i> </span> </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="nav ml-auto">
                <li class="nav-item active dropdown"> <a class="nav-link" [ngStyle]="{'color':color === 'black' ? 'black' : null }" href="/">Home</a> </li>
                <!--<li class="nav-item"> <a class="nav-link" href="/products">Products</a> </li>
                <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" data-toggle="dropdown"  href="/services">Products<b class="caret"></b></a> 
                  <ul class="dropdown-menu">
                    <li><a class="nav-link" style="color:black;font-size: smaller;" href="/saas-product-design-and-development">Pure Kubernetes</a></li>
                    <li><a class="nav-link" style="color:black;font-size: smaller;" href="/messaging-payments-shopping-cart-api">NodeAsset API</a></li>
                </ul>
                  </li>-->
                  <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" data-toggle="dropdown"  [ngStyle]="{'color':color === 'black' ? 'black' : null }" href="/services">Services<b class="caret"></b></a> 
                    <ul class="dropdown-menu">
                      <!--<li><a class="nav-link" style="color:black;font-size: smaller;" href="/blockchain-as-a-service-baas">Blockchain as a Serice (Baas)</a></li>-->
                      <li><a class="nav-link" style="color:black;font-size: smaller;" href="/managed-cloud-operations">Managed Cloud Operations</a></li>
                      <li><a class="nav-link" style="color:black;font-size: smaller;" href="/saas-product-design-and-development">Open Source Technology & Data Migration</a></li>
                  </ul>
                    </li>
                    <!--<li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" data-toggle="dropdown"  [ngStyle]="{'color':color === 'black' ? 'black' : null }" href="/services">Products<b class="caret"></b></a> 
                      <ul class="dropdown-menu">
                        <li><a class="nav-link" style="color:black;font-size: smaller;" href="#">NodeAsset PX</a></li>
                        <li><a class="nav-link" style="color:black;font-size: smaller;" href="#">NodeAsset ICO</a></li>
                        <li><a class="nav-link" style="color:black;font-size: smaller;" href="#">NodeAsset ECO</a></li>
                    </ul>
                      </li>-->
                <li class="nav-item "> <a class="nav-link " [ngStyle]="{'color':color === 'black' ? 'black' : null }" href="/about">About Us</a> </li>
                <li class="nav-item "> <a class="nav-link " href="/contact">Contact</a> </li>
                <!--<li class="nav-item "> <a class="nav-link " [ngStyle]="{'color':color === 'black' ? 'black' : null }" href="/investors">Investors</a> </li>-->
              </ul>
            </div>
          </div>
        </nav>
      </header>
<router-outlet></router-outlet>
<!--<app-footer></app-footer>-->
<!-- Start Footer -->
<section class="footer bg-second">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center mt-40">
              <ul class="social">
                <li><a href="#"><i class="fab fa-facebook-square"></i></a></li>
                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                <li><a href="#"><i class="fab fa-github-square"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter-square"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="row mt-30 text-center">
            <div class="col-md-12">
              <p>&copy; @ Copyright 2020-21 All Right Reserved Georgetown Data Services Inc.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="contact">Contact Us</a></p>
            </div>
          </div>
        </div>
      </section>
<script>

  $(document).ready(function(){
  
     $('.mobile-toggle').click(function(){
       $('ul.nav').toggleClass('w-menu');
      })
      $('.close-toggle').click(function(){
     $('ul.nav').removeClass('w-menu'); 
     })
     })
  
  
  
  
  </script>