import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  $: any;
  carouselOptions = {
    margin: 0,
    nav: false,
    responsiveClass: false,
    responsive: {
      0: {
        items: 1,
        nav: false
      },
      600: {
        items: 1,
        nav: false
      },
      1000: {
        items: 1,
        nav: false,
        loop: false
      },
      1500: {
        items: 1,
        nav: false,
        loop: false
      }
    }
  }

  images = [
    {
      text: "Everfresh Flowers",
      image: "/assets/images/slider/slide1.jpg"
    },
    {
      text: "Festive Deer",
      image: "/assets/images/slider/slide2.jpg"
    },
    {
      text: "Morning Greens",
      image: "/assets/images/slider/slide3.jpg"
    }
  ]


  constructor() { }

  ngOnInit(): void {
  }

}
