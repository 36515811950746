import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { BlogComponent } from './components/blog/blog.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProductsComponent } from './components/products/products.component';
import { ServicesComponent } from './components/services/services.component';
import {APP_BASE_HREF} from '@angular/common';

// Import your library
import { OwlModule } from 'ngx-owl-carousel';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BaasComponent } from './components/baas/baas.component';
import { CaseStudyComponent } from './components/case-study/case-study.component';
import { DesignComponent } from './components/design/design.component';
import { LegacyComponent } from './components/legacy/legacy.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { CloudComponent } from './components/cloud/cloud.component';

import { ContactService } from './contactService.service';
import { Http, HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { PrivateExchangeComponent } from './components/private-exchange/private-exchange.component';
import { AssetAccountsComponent } from './components/asset-accounts/asset-accounts.component';
import { IcoComponent } from './components/ico/ico.component';
import { RelatedStoriesComponent } from './components/related-stories/related-stories.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { WorkWithUsComponent } from './components/work-with-us/work-with-us.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    BlogComponent,
    ContactComponent,
    ProductsComponent,
    ServicesComponent,
    HeaderComponent,
    FooterComponent,
    BaasComponent,
    CaseStudyComponent,
    DesignComponent,
    LegacyComponent,
    WorkflowComponent,
    CloudComponent,
    ProductDetailComponent,
    PrivateExchangeComponent,
    AssetAccountsComponent,
    IcoComponent,
    RelatedStoriesComponent,
    ProductListComponent,
    WorkWithUsComponent
  ],
  imports: [
    HttpClientModule,
    HttpModule,
    BrowserModule,
    AppRoutingModule,
    OwlModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [{provide: APP_BASE_HREF, useValue: '/'}, ContactService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
