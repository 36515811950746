import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BlogComponent } from './components/blog/blog.component';
import { BaasComponent } from './components/baas/baas.component';
import { ContactComponent } from './components/contact/contact.component';
import { LegacyComponent } from './components/legacy/legacy.component';
import { ServicesComponent } from './components/services/services.component';
import { AboutComponent } from './components/about/about.component';
import { CloudComponent } from './components/cloud/cloud.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { CaseStudyComponent } from './components/case-study/case-study.component';
import { DesignComponent } from './components/design/design.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { IcoComponent } from './components/ico/ico.component';
import { AssetAccountsComponent } from './components/asset-accounts/asset-accounts.component';
import { PrivateExchangeComponent } from './components/private-exchange/private-exchange.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blockchain-as-a-service-baas', component: BaasComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'saas-product-design-and-development', component: DesignComponent },
  { path: 'legacy-modernization', component: LegacyComponent },
  { path: 'managed-cloud-operations', component: CloudComponent },
  { path: 'workflow-automation', component: WorkflowComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'case-study', component: CaseStudyComponent },
  { path: 'investors', component: ProductsComponent },
  { path: 'investment-product', component: ProductDetailComponent },
  { path: 'initial-coin-offering-ico-cryptocurrency', component: IcoComponent },
  { path: 'private-blockchain-exchange', component: PrivateExchangeComponent },
  { path: 'cryptocurrency-asset-accounts-blockchain', component: AssetAccountsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
