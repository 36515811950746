import { HttpEvent, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import * as _ from 'lodash';
import { HttpModule } from '@angular/http';
import { apiUrlConst } from './const/apiConst';

@Injectable()
export class ContactService {
  constructor(public http: Http, public _httpclient: HttpClient) {}

  sendEmail(body: any) {
    return this.http.post(apiUrlConst.BASE_URL + apiUrlConst.sendEmail, body);
  }

  extractData(res: Response) {
    console.log('saveContactUs res', res);
    const body = res.json();
    return body.data || {};
  }
}
