<body class="inner_page contact_page">
	<section class="inner_hero_sec">
		<div class="container position-relative h-100">
			<div class="inner_hero_content">
				<h1>Contact Us</h1>
				<ol class="breadcrumb pl-0">
					<li class="breadcrumb-item"><a href="index.html">Home</a></li>
					<li class="breadcrumb-item active">Contact</li>
				</ol>
			</div>
		</div>
	</section>
	<div class="page_wrapper">
		<section class="contact_page">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="cont_info">
							<h2>Share your idea with us via text or email</h2>

							<ul class="p-0">
								<li>
									<div class="contadt-title"><i class="fa fa-mobile-alt"></i></div>
									<p>(816)945-7992</p>
								</li>
								<li>
									<div class="contadt-title"><i class="fa fa-envelope"></i></div>
									<p>tony.Tolbert@georgetowndataservices.com</p>
								</li>
							</ul>
						</div>

					</div>
					<div class="col-md-6">
						<div class="cont_form">
							<div class="sec_heading mb-4"> <span class="h3">Work with us on your next project</span>
								<p>Stand against outdated methods and start building automated & cloud-centric systems
									that create value.</p>
							</div>
							<form [formGroup]="contactForm">

								<div class="form-group">
									<input type="text" id="name" formControlName="name" class="form-control" required>
									<label class="form-control-placeholder" for="name">Name</label>
									<div class="error" *ngIf="contactForm.get('name').hasError('required') && contactForm.get('name').touched">
										Name is required.
									  </div>
								</div>
								
								<div class="form-group">
									<input type="text" id="email" formControlName="email" class="form-control" required>
									<label class="form-control-placeholder" for="password">Email</label>
									<div class="error" *ngIf="contactForm.get('email').hasError('email') && contactForm.get('email').touched">
										Please enter a valid email address.
									  </div>
									  <div class="error" *ngIf="contactForm.get('email').hasError('required') && contactForm.get('email').touched">
										Email is required.
									  </div>
								</div>
								
								<div class="form-group">
									<input type="text" id="phone" formControlName="phone" class="form-control"
										required>
									<label class="form-control-placeholder" for="Phone">Phone No</label>
									<div class="error" *ngIf="contactForm.get('phone').hasError('required') && contactForm.get('phone').touched">
										Phone is required.
									  </div>
								</div>
								
								<div class="form-group">
									<textarea rows="2" formControlName="message" id="message" class="form-control"
										></textarea>

									<label class="form-control-placeholder" for="contact_text">Message</label>
								</div>

								<div class="text-center">

									<button (click)="sendDetail()" [disabled]="formsubmit" class="main-button bg-transparent">Send <i
											class="fa fa-long-arrow-alt-right"></i></button>
								</div>
							</form>

						</div>

					</div>
				</div>
			</div>
		</section>

	</div>
</body>