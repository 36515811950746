import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-related-stories',
  templateUrl: './related-stories.component.html',
  styleUrls: ['./related-stories.component.css']
})

export class RelatedStoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
