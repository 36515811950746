import { Component, OnInit } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';

//service file
import { ContactService } from '../../contactService.service';
// import { SnackbarService } from ''

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  disabled: boolean;
  formsubmit: boolean;
  constructor(public _contactService: ContactService) {}

  contactForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', Validators.required),
    message: new FormControl(''),
  });

  ngOnInit() {}

  //on Sending
  sendDetail() {
    this.formsubmit = true;
    if (!this.contactForm.valid) return;
    const { name, email, phone, message } = this.contactForm.value;
    this._contactService.sendEmail({ name, email, phone, message }).subscribe(
      (res) => {
        this.formsubmit = false;
        this.contactForm.reset();
      },
      (err) => {
        this.formsubmit = false;
        console.log('err', err);
      }
    );
  }

  /*openSnackBar(message: string, action: string, type: string) {
    let panelClass = '';
    if (type === 'success') {
      panelClass = 'blue-snackbar';
    } else {
      panelClass = 'error-snackbar';
    }
    const snackBarAction = this._snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: [panelClass],
    });
    snackBarAction.afterDismissed().subscribe(() => {
    });
  }*/
}
