<body class="inner_page product_page">
    <div class="page_wrapper"> 
    <!-- Start Blog -->
<div class="container">
          <div class="cart_option">
            <div aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item" aria-current="page"><a href="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Crypto Asset Accounts</li>
              </ol>
            </div>
              
          </div>
        
          


          <div class="row">
            <div class="col-md-3">
              <div class="product_filter">
                  <strong class="d-block h1"  data-toggle="collapse" href="#filter_cate" role="button" aria-expanded="false" aria-controls="filter_cate">Investments</strong>
               <div class="collapse show" id="filter_cate">
                   <ul>
                  <li><a href="#">All</a></li>
                    <li><a href="#">Crypto <span class="f_count">20</span></a></li>
                    <li><a href="#">Fiat <span class="f_count">50</span></a></li>
                    <li><a href="#">Stocks <span class="f_count">10</span></a></li>
                    <li><a href="#">Metals <span class="f_count">12</span></a></li>
                  </ul></div>
                </div>
                <!--<div class="product_filter">
                  <strong class="d-block h1"  data-toggle="collapse" href="#filter_price" role="button" aria-expanded="false" aria-controls="filter_price">Price</strong>
               <div class="collapse show" id="filter_price">
                   <ul>
                  <li><a href="#">All</a></li>
                    <li><a href="#">Under $20 <span class="f_count">20</span></a></li>
                    <li><a href="#">$20 - $40 <span class="f_count">50</span></a></li>
                    <li><a href="#">$20 - $40 <span class="f_count">10</span></a></li>
                    <li><a href="#">$20 - $40 <span class="f_count">12</span></a></li>
                    <li><a href="#">$20 - $40 <span class="f_count">15</span></a></li>
                    <li><a href="#">$20 - $40 <span class="f_count">55</span></a></li>
                    <li><a href="#">$20 - $40 <span class="f_count">30</span></a></li>
                  </ul></div>
                </div>-->
              
              </div>
              
            <div class="col-md-9">
                <!-- Left and right controls -->
                <div >
                      <h1>Cryptocurrency Asset Accounts</h1>
                      <p>NodeAsst uses machine learning and artifical intelligence (AI) and high-frequency trading to facillitate buy and sell orders for various assets. Open an account in base cyrpto-currency (NAHC) to begin investing. After opening a NAHC account, you will be able to trade in the various NodeAsset holdings shown below.</p>
                    
                                   
                    </div>
                <div class="product_container">
                    <div class="product_head">
                      <div class="sec_heading"> <span class="h3">Fiat Currency</span> </div>
                      <div class="left_head"> <span class="item_count">4 Items</span> <a href="#" style="display: none;">See All <i class="fa fa-angle-right"></i></a> </div>
                    </div>  
          <div class="row">
                                                    <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                      <div class="product_card">
                                                      <a href="investment-product"><div class="product_thumb">
                                                          <img src="/assets/images/investments/united-states-dollar.jpg" alt="Product Image"/>
                                                          </div>
                                                          <div class="card_body">
                                                              <span class="cat_name">Fiat Currency</span>
                                                          <strong class="h1">USD</strong>
                                                              <p>United States Do<br>
                                                                  +.035% past 12 months</p>
                                                              <strong class="list_price">
                                                              <span class="currency">US $</span><span class="num">325</span>
                                                              </strong>
                                                          </div></a>
                                                          <div class="card_foot">
                                                          <a href="investment-product">Add To Cart</a>
                                                          </div>
                                                      </div>
                                                      </div>
                                                      <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                      <div class="product_card">
                                                  <a href="investment-product">	<div class="product_thumb">
                                                          <img src="/assets/images/investments/euro-dollar.jpg" alt="Product Image"/>
                                                          </div>
                                                          <div class="card_body">
                                                              <span class="cat_name">Fiat Currency</span>
                                                          <strong class="h1">EUR</strong>
                                                              <p>European Union Currency <br>
          +.025% past 12 months</p><strong class="list_price">
                                                              <span class="currency">EUR $</span><span class="num">1.45</span>
                                                              </strong>
                                                          </div></a>
                                                          <div class="card_foot">
                                                          <a href="investment-product">Add To Cart</a>
                                                          </div>
                                                      </div>
                                                      </div>
                                                      <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                      <div class="product_card">
                                                      <a href="investment-product"><div class="product_thumb">
                                                          <img src="/assets/images/investments/cfa-west-african-franc.jpg" alt="Product Image"/>
                                                          </div>
                                                          <div class="card_body">
                                                              <span class="cat_name">Fiat Currency</span>
                                                          <strong class="h1">CFA</strong>
                                                              <p>West African Franc <br>
                                                                  +.0035% past 12 months</p><strong class="list_price">
                                                              <span class="currency">US $</span><span class="num">1800</span>
                                                              </strong>
                                                          </div></a>
                                                          <div class="card_foot">
                                                          <a href="investment-product">Add To Cart</a>
                                                          </div>
                                                      </div>
                                                      </div>
                                                      <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                      <div class="product_card">
                                                  <a href="investment-product">	<div class="product_thumb">
                                                          <img src="/assets/images/investments/brittish-pound.jpg" alt="Product Image"/>
                                                          </div>
                                                          <div class="card_body">
                                                              <span class="cat_name">Fiat Currency</span>
                                                          <strong class="h1">PND</strong>
                                                              <p>Brittish Pound<br>
          +.25% last 12 months</p><strong class="list_price">
                                                              <span class="currency">US $</span><span class="num">1.75</span>
                                                              </strong>
                                                          </div></a>
                                                          <div class="card_foot">
                                                          <a href="investment-product">Add To Cart</a>
                                                          </div>
                                                      </div>
                                                      </div>
                                                      
                                                
                                                </div>



</div>

                <div class="product_container">
                <div class="product_head">
                  <div class="sec_heading"> <span class="h3">Cryptocurrencies</span> </div>
                  <div class="left_head"> <span class="item_count">4 Items</span> <a href="#" style="display: none;">See All <i class="fa fa-angle-right"></i></a> </div>
                </div>  
      <div class="row">
        <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
            <div class="product_card">
        <a href="investment-product">	<div class="product_thumb">
                <img src="/assets/images/investments/nahc-logo1.jpg" alt="Product Image"/>
                </div>
                <div class="card_body">
                    <span class="cat_name">Cryptocurrency</span>
                <strong class="h1">NAHC</strong>
                    <p>NodeAsset Holdings Coin (NAHC) <br>
+35% past 12 months</p><strong class="list_price">
                    <span class="currency">US $</span><span class="num">1</span>
                    </strong>
                </div></a>
                <div class="card_foot">
                <a href="investment-product">Add To Cart</a>
                </div>
            </div>
            </div>
                                                <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                  <div class="product_card">
                                                  <a href="investment-product"><div class="product_thumb">
                                                      <img src="/assets/images/investments/bitcoin-logo.png" alt="Product Image"/>
                                                      </div>
                                                      <div class="card_body">
                                                          <span class="cat_name">Cryptocurrency</span>
                                                      <strong class="h1">BTC</strong>
                                                          <p>Bitcoin<br>
      THC 44.22% CBD 45.27%</p>
                                                          <strong class="list_price">
                                                          <span class="currency">US $</span><span class="num">325</span>
                                                          </strong>
                                                      </div></a>
                                                      <div class="card_foot">
                                                      <a href="investment-product">Add To Cart</a>
                                                      </div>
                                                  </div>
                                                  </div>
                                                  
                                                  <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                  <div class="product_card">
                                                  <a href="investment-product"><div class="product_thumb">
                                                      <img src="/assets/images/investments/ethereum-logo.png" alt="Product Image"/>
                                                      </div>
                                                      <div class="card_body">
                                                          <span class="cat_name">Cryptocurrency</span>
                                                      <strong class="h1">ETHR</strong>
                                                          <p>Ethereum Smart Contracts <br>
      +77% last 12 months</p><strong class="list_price">
                                                          <span class="currency">US $</span><span class="num">1800</span>
                                                          </strong>
                                                      </div></a>
                                                      <div class="card_foot">
                                                      <a href="investment-product">Add To Cart</a>
                                                      </div>
                                                  </div>
                                                  </div>
                                                  <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                  <div class="product_card">
                                              <a href="investment-product">	<div class="product_thumb">
                                                      <img src="/assets/images/investments/dogecoin-logo.jpeg" alt="Product Image"/>
                                                      </div>
                                                      <div class="card_body">
                                                          <span class="cat_name">Cryptocurrency</span>
                                                      <strong class="h1">DOGE</strong>
                                                          <p>Dogecoin peer to peer crypto<br>
      +25% last 12 months</p><strong class="list_price">
                                                          <span class="currency">US $</span><span class="num">8.00</span>
                                                          </strong>
                                                      </div></a>
                                                      <div class="card_foot">
                                                      <a href="investment-product">Add To Cart</a>
                                                      </div>
                                                  </div>
                                                  </div>
                                                  
                                            
                                            </div>
                                      <br>
                                    
                                            <div class="product_container">
                                                <div class="product_head">
                                                  <div class="sec_heading"> <span class="h3">Professional Sports Leagues</span> </div>
                                                  <div class="left_head"> <span class="item_count">4 Items</span> <a href="#" style="display: none;">See All <i class="fa fa-angle-right"></i></a> </div>
                                                </div>  
                                      <div class="row">
                                        <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                            <div class="product_card">
                                        <a href="investment-product">	<div class="product_thumb">
                                                <img src="/assets/images/investments/nba-logo.png" alt="Product Image"/>
                                                </div>
                                                <div class="card_body">
                                                    <span class="cat_name">Sports</span>
                                                <strong class="h1">NBA</strong>
                                                    <p>National Basketball Association<br>
                                +35% past 12 months</p><strong class="list_price">
                                                    <span class="currency">US $</span><span class="num">1</span>
                                                    </strong>
                                                </div></a>
                                                <div class="card_foot">
                                                <a href="investment-product">Add To Cart</a>
                                                </div>
                                            </div>
                                            </div>
                                                                                <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                                                  <div class="product_card">
                                                                                  <a href="investment-product"><div class="product_thumb">
                                                                                      <img src="/assets/images/investments/mlb-logo.png" alt="Product Image"/>
                                                                                      </div>
                                                                                      <div class="card_body">
                                                                                          <span class="cat_name">Sports</span>
                                                                                      <strong class="h1">MLB</strong>
                                                                                          <p>Major League Baseball (MLB)<br>
                                      THC 44.22% CBD 45.27%</p>
                                                                                          <strong class="list_price">
                                                                                          <span class="currency">US $</span><span class="num">325</span>
                                                                                          </strong>
                                                                                      </div></a>
                                                                                      <div class="card_foot">
                                                                                      <a href="investment-product">Add To Cart</a>
                                                                                      </div>
                                                                                  </div>
                                                                                  </div>
                                                                                  
                                                                                  <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                                                  <div class="product_card">
                                                                                  <a href="investment-product"><div class="product_thumb">
                                                                                      <img src="/assets/images/investments/nfl-logo.png" alt="Product Image"/>
                                                                                      </div>
                                                                                      <div class="card_body">
                                                                                          <span class="cat_name">Sports</span>
                                                                                      <strong class="h1">NFL</strong>
                                                                                          <p>National Football League (NFL) <br>
                                      +77% last 12 months</p><strong class="list_price">
                                                                                          <span class="currency">US $</span><span class="num">1800</span>
                                                                                          </strong>
                                                                                      </div></a>
                                                                                      <div class="card_foot">
                                                                                      <a href="investment-product">Add To Cart</a>
                                                                                      </div>
                                                                                  </div>
                                                                                  </div>
                                                                                  <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                                                  <div class="product_card">
                                                                              <a href="investment-product">	<div class="product_thumb">
                                                                                      <img src="/assets/images/investments/mls-logo.png" alt="Product Image"/>
                                                                                      </div>
                                                                                      <div class="card_body">
                                                                                          <span class="cat_name">Sports</span>
                                                                                      <strong class="h1">MLS</strong>
                                                                                          <p>Major League Soccer (MLS)<br>
                                      +25% last 12 months</p><strong class="list_price">
                                                                                          <span class="currency">US $</span><span class="num">8.00</span>
                                                                                          </strong>
                                                                                      </div></a>
                                                                                      <div class="card_foot">
                                                                                      <a href="investment-product">Add To Cart</a>
                                                                                      </div>
                                                                                  </div>
                                                                                  </div>
                                                                                  
                                                                            
                                                                            </div>
                                                                      
                                                                    
                                            </div>
                                

               <!-- Left and right controls -->
                      
               <div class="product_container">
                <div class="product_head">
                  <div class="sec_heading"> <span class="h3">Metals</span> </div>
                  <div class="left_head"> <span class="item_count">4 Items</span> <a href="#" style="display: none;">See All <i class="fa fa-angle-right"></i></a> </div>
                </div>  
      <div class="row">
                                                <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                  <div class="product_card">
                                                  <a href="investment-product"><div class="product_thumb">
                                                      <img src="/assets/images/investments/gold-bars.jpg" alt="Product Image"/>
                                                      </div>
                                                      <div class="card_body">
                                                          <span class="cat_name">Precious Metals</span>
                                                      <strong class="h1">GLD</strong>
                                                          <p>Gold Bullion<br>
                                                              +.035% past 12 months</p>
                                                          <strong class="list_price">
                                                          <span class="currency">US $</span><span class="num">325</span>
                                                          </strong>
                                                      </div></a>
                                                      <div class="card_foot">
                                                      <a href="investment-product">Add To Cart</a>
                                                      </div>
                                                  </div>
                                                  </div>
                                                  <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                  <div class="product_card">
                                              <a href="investment-product">	<div class="product_thumb">
                                                      <img src="/assets/images/investments/silver-bars.jpg" alt="Product Image"/>
                                                      </div>
                                                      <div class="card_body">
                                                          <span class="cat_name">Precious Metals</span>
                                                      <strong class="h1">SLV</strong>
                                                          <p>Silver Bullion <br>
      +.025% past 12 months</p><strong class="list_price">
                                                          <span class="currency">USD $</span><span class="num">1.45</span>
                                                          </strong>
                                                      </div></a>
                                                      <div class="card_foot">
                                                      <a href="investment-product">Add To Cart</a>
                                                      </div>
                                                  </div>
                                                  </div>
                                                  <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                  <div class="product_card">
                                                  <a href="investment-product"><div class="product_thumb">
                                                      <img src="/assets/images/investments/copper-bars.jpg" alt="Product Image"/>
                                                      </div>
                                                      <div class="card_body">
                                                          <span class="cat_name">Precious Metals</span>
                                                      <strong class="h1">COP</strong>
                                                          <p>Copper Bullion <br>
                                                              +.0035% past 12 months</p><strong class="list_price">
                                                          <span class="currency">US $</span><span class="num">1800</span>
                                                          </strong>
                                                      </div></a>
                                                      <div class="card_foot">
                                                      <a href="investment-product">Add To Cart</a>
                                                      </div>
                                                  </div>
                                                  </div>
                                                  <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                                  <div class="product_card">
                                              <a href="investment-product">	<div class="product_thumb">
                                                      <img src="/assets/images/investments/platinum-bars.jpg" alt="Product Image"/>
                                                      </div>
                                                      <div class="card_body">
                                                          <span class="cat_name">Precious Metals</span>
                                                      <strong class="h1">PLT</strong>
                                                          <p>Platinum Bullion<br>
      +.25% last 12 months</p><strong class="list_price">
                                                          <span class="currency">US $</span><span class="num">1.75</span>
                                                          </strong>
                                                      </div></a>
                                                      <div class="card_foot">
                                                      <a href="investment-product">Add To Cart</a>
                                                      </div>
                                                  </div>
                                                  </div>
                                                  
                                            
                                            </div>



</div>

 <!-- Left and right controls -->
                      
 <div class="product_container">
    <div class="product_head">
      <div class="sec_heading"> <span class="h3">Stocks</span> </div>
      <div class="left_head"> <span class="item_count">4 Items</span> <a href="#" style="display: none;">See All <i class="fa fa-angle-right"></i></a> </div>
    </div>  
<div class="row">
                                    <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                      <div class="product_card">
                                      <a href="investment-product"><div class="product_thumb">
                                          <img src="/assets/images/investments/pepsico-stock.jpg" alt="Product Image"/>
                                          </div>
                                          <div class="card_body">
                                              <span class="cat_name">Stocks</span>
                                          <strong class="h1">PEP</strong>
                                              <p>PepsiCo<br>
                                                  +.035% past 12 months</p>
                                              <strong class="list_price">
                                              <span class="currency">US $</span><span class="num">325</span>
                                              </strong>
                                          </div></a>
                                          <div class="card_foot">
                                          <a href="investment-product">Add To Cart</a>
                                          </div>
                                      </div>
                                      </div>
                                      <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                      <div class="product_card">
                                  <a href="investment-product">	<div class="product_thumb">
                                          <img src="/assets/images/investments/jumia-stock.jpg" alt="Product Image"/>
                                          </div>
                                          <div class="card_body">
                                              <span class="cat_name">Stocks</span>
                                          <strong class="h1">JMI</strong>
                                              <p>Jumia <br>
+.025% past 12 months</p><strong class="list_price">
                                              <span class="currency">USD $</span><span class="num">1.45</span>
                                              </strong>
                                          </div></a>
                                          <div class="card_foot">
                                          <a href="investment-product">Add To Cart</a>
                                          </div>
                                      </div>
                                      </div>
                                      <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                      <div class="product_card">
                                      <a href="investment-product"><div class="product_thumb">
                                          <img src="/assets/images/investments/amazon-stock.jpeg" alt="Product Image"/>
                                          </div>
                                          <div class="card_body">
                                              <span class="cat_name">Stocks</span>
                                          <strong class="h1">AMZ</strong>
                                              <p>Amazon <br>
                                                  +.0035% past 12 months</p><strong class="list_price">
                                              <span class="currency">US $</span><span class="num">1800</span>
                                              </strong>
                                          </div></a>
                                          <div class="card_foot">
                                          <a href="investment-product">Add To Cart</a>
                                          </div>
                                      </div>
                                      </div>
                                      <div class="col-md-3 col-sm-12 col-12 d-flex flex-column">
                                      <div class="product_card">
                                  <a href="investment-product">	<div class="product_thumb">
                                          <img src="/assets/images/investments/microsoft-stock.jpg" alt="Product Image"/>
                                          </div>
                                          <div class="card_body">
                                              <span class="cat_name">Stocks</span>
                                          <strong class="h1">MST</strong>
                                              <p>Microsoft Corportation<br>
+.25% last 12 months</p><strong class="list_price">
                                              <span class="currency">US $</span><span class="num">1.75</span>
                                              </strong>
                                          </div></a>
                                          <div class="card_foot">
                                          <a href="investment-product">Add To Cart</a>
                                          </div>
                                      </div>
                                      </div>
                                      
                                
                                </div>



</div>
      
            </div>
          </div>
          </div>
        <!-- End Blog --> 
        </div>
    </div>
    </body>
        