<body class="inner_page product_page">
<div class="page_wrapper"> 
    <!-- Start Blog -->
    
    <div class="container">
      <div class="cart_option">
        <div aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item" aria-current="page"><a href="/">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page"><a href="investors">Crypto Asset Accounts</a></li>
              <li class="breadcrumb-item active" aria-current="page">Gold</li>
          </ol>
        </div>
          
      </div>
      <section class="product_detail">
        <div class="container">
          <div class="card">
      <div class="row">
          <aside class="col-sm-5 border-right">
  <article class="gallery-wrap"> 
  <div class="img-big-wrap">
    <div> <a href="#"><img src="/assets/images/investments/gold-bars.jpg" alt="Product Image"/></a></div>
  </div> <!-- slider-product.// -->
  
  </article> <!-- gallery-wrap .end// -->
          </aside>
          <aside class="col-sm-7">
  <article class="card-body pl-md-5 pr-md-5 pt-md-0  pr-0 pl-0">
      <span class="badge">Precious Metals</span>
      <h3 class="title mb-3">Gold Bullion</h3>
      <p class="price-detail-wrap"> 
      <span class="price h3 "> 
          <span class="currency">US $</span><span class="num">1299</span>
      </span> 
      
  </p> <!-- price-detail-wrap .// -->
  <div class="rating">
      <span>46</span> <i class="fa fa-star"></i> <strong>72 Review</strong>
      </div>
  
  
  <dl class="param param-feature">
    <dt>DETAILS#</dt>
    <dd >+ 19.08%</dd>
  </dl>  <!-- item-property-hor .// -->
  
  <dl class="item-property">
    <dt>Description</dt>
    <dd><p>Here goes description consectetur adipisicing elit, sed do eiusmod
  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
  quis nostrud exercitation ullamco </p></dd>
  </dl>
      
      <dl class="share-item">
      <dt>Share</dt>
          <dd>
          <ul>
              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              </ul>
          </dd>
      
      </dl>
      
  <hr>
      <div class="row">
          <div class="col-sm-12 text-left">
              <dl class="param param-inline">
                <dt>Quantity: </dt>
                <dd>
                <div class="qty mt-2 text-left">
                          <span class="minus">-</span>
                          <input type="number" class="count" name="qty" value="1">
                          <span class="plus">+</span>
                      </div>
                </dd>
              </dl>  <!-- item-property .// -->
          </div> <!-- col.// -->
          
      </div> <!-- row.// -->
      <hr>
      <div class="cart_btn_group"><a href="#" class="btn btn-lg btn-primary text-uppercase"> Buy now </a>
      <a href="#" class="btn btn-lg btn-outline-primary text-uppercase"> <i class="fas fa-shopping-cart"></i> Add to cart </a></div>
  </article> <!-- card-body.// -->
          </aside> <!-- col.// -->
      </div> <!-- row.// -->
  </div> <!-- card.// -->
          </div>
        
        </section>
    </div>
    <!-- End Blog --> 
    </div>
    </body>