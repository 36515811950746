<body class="inner_page blog_list blog_detail">
    <section class="inner_hero_sec">
      <div class="container position-relative h-100">
        <div class="inner_hero_content">
          <h1>Blockchain Asset accounts</h1>
          <ol class="breadcrumb pl-0">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active">Blockchain Asset Accounts</li>
          </ol>
        </div>
      </div>
    </section>
    <div class="page_wrapper">
        <!-- Start Blog -->
      <section class="blog_detail">
        <div class="container">
        <div class="row">  	<div class="col-md-8">
            <div class="blog_hero">
                <!--<img src="/assets/images/3.jpg" alt="Blog Title Image"/>-->
                <div class="blog_hero_content">
                <div class="blog_hero_detail">
                    <ul>
                        <li><div class="auther_detial"><img src="/assets/images/author.jpg" alt="Author Image"/> <span>Earl Holland <small>(Author)</small></span></div></li>
                        <li><i class="fa fa-calendar-alt"></i> <span class="blog_date">26 Jan 2021</span></li>
                        
                    
                    </ul>
                    <div class="blog_social">
                        <a href="#"><i class="fab fa-facebook-square"></i> <span class="sr-only">Blog Share On Facebook</span></a>
                        <a href="#"><i class="fab fa-twitter-square"></i> <span class="sr-only">Blog Share On twitter</span></a>
                        <a href="#"><i class="fab fa-google-plus-square"></i> <span class="sr-only">Blog Share On google-plus</span></a>
                
                    
                    </div>
                    </div>
                <h2 class="blog_hero_title">NodeAsset Blockchain-based Cyrptocurrency Asset Accounts</h2>
                </div>
                
                
                </div>
                
                <div class="blog_body">
                <p>NodeAsset crypto-currency asset accounts hold the tokenized value of physical assets like US dollars or gold in blockchain based accounts. 
                    NAHC investors are eligible to open digital asset accounts corresponding to the 
                    current basket of assets that make up the NAHC. Holders of the NAHC are also eligible to take advantage 
                of emerging trends with financial technology with machine learning, artificial intelligence, and algorithmic trading. By moving funds 
            into asset accounts our customers are able to take advantage of the gains generated from the algorithm assigned to that asset class. </p>
             

               <p>The following <a href="investors">asset classes are currently held and actively traded by NodeAsset, LLC</a>. 
                The mix of these assets may change based on market conditions.</p>
                
                
                <ul>
                    <li>Fiat Currency - US Dollar, British Pound, West African Franc, Euro.</li>
                
                <li>Crypto Currency - NAHC, Dogecoin, Bitcoin, Ethereum.</li>
                
                <li>Precious Metals - Gold, Silver, Platinum, Copper</li>
                
                <li>Corporate Stock - PepsiCo, Amazon, Jumia, Microsoft</li></ul>

                <p>Create a nodeasset cyrpto-currency account in our Private Exchange to take advantage of the same technologies that hedge funds use to make money in public markets. <a href="investors">Create an asset account</a> today to get started 
                    saving and investing in the crypto, metals, stocks, and currency markets.</p>
            </div>
            <app-work-with-us></app-work-with-us>
            </div>
            <div class="col-md-4">
            <div class="related_blog">
            <div class="sec_heading"> <span class="h3">Blockchain Services</span>
           
          </div>
                
          <app-related-stories></app-related-stories>
                
                </div>
            
            </div></div>
          </div>
        
        </section>
      
    
      
    </div>
    </body>