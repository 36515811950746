import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-baas',
  templateUrl: './baas.component.html',
  styleUrls: ['./baas.component.css']
})
export class BaasComponent implements OnInit {
  title = 'Blockchain as a Service (BaaS)';
  keywords = 'blockchain, cryptocurrency, ethereum, bitcoin, dogecoin, hyperledger, cloud, kubernetes, smart contract, digital wallet';
  description = 'NodeAsset designs, builds, and manages cloud-based blockchain infrastructure for corporations, municipalities, governments, and educational institutions.';
  sitename = 'nodeasset.com';
  url = 'https://www.nodeasset.com/blockchain-as-a-service-daas';
  image = 'https://www.nodeasset.com/assets/images/web-image.png';
  constructor(private metaTagService: Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'title', content: this.title}
    );
    this.metaTagService.updateTag(
      { name: 'description', content: this.description}
    );
    this.metaTagService.updateTag(
      { name: 'keywords', content: this.keywords}
    );
    this.metaTagService.updateTag(
      { property: 'og:site_name', content: this.sitename}
    );
    this.metaTagService.updateTag(
      { property: 'og:url', content: this.url}
    );
    this.metaTagService.updateTag(
      { property: 'og:description', content: this.description}
    );
    this.metaTagService.updateTag(
      { property: 'og:image', content: this.image}
    );
    this.metaTagService.updateTag(
      { property: 'og:title', content: this.title}
    );
  }
}