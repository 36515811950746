<body class="inner_page blog_list">
    <section class="inner_hero_sec">
      <div class="container position-relative h-100">
        <div class="inner_hero_content">
          <h1>Blog List Pages</h1>
          <ol class="breadcrumb pl-0">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item active">Blogs</li>
          </ol>
        </div>
      </div>
    </section>
    <div class="page_wrapper">
        <!-- Start Blog -->
      <section class="blog bg-main" >
        <div class="container">
       
          
              <div class="item" >
                <div class="post-img">
                  <div class="img"> <img src="images/1.jpg" alt="1"> </div>
                </div>
                <div class="cont">
                  <div class="info"> <a href="#0">06 Aug 2018</a> <a href="#0">by Earl Holland</a> </div>
                  <h6><a href="#0">NodeAsset Blockchain as a Service (BaaS)</a></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the dummy text ever</p>
                </div>
              </div>
             <div class="item" >
                <div class="post-img">
                  <div class="img"> <img src="images/2.jpg" alt="1"> </div>
                </div>
                <div class="cont">
                  <div class="info"> <a href="#0">06 Aug 2018</a> <a href="#0">by Anya Stark</a> </div>
                  <h6><a href="#0">This Simple Yet Powerful Weekly Planning Routine Puts You in Control</a></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the dummy text ever</p>
                </div>
              </div>
             <div class="item" >
                <div class="post-img">
                  <div class="img"> <img src="images/3.jpg" alt="1"> </div>
                </div>
                <div class="cont">
                  <div class="info"> <a href="#0">06 Aug 2018</a> <a href="#0">by Anya Stark</a> </div>
                  <h6><a href="#0">This Simple Yet Powerful Weekly Planning Routine Puts You in Control</a></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the dummy text ever</p>
                </div>
              </div>
             <div class="item" >
                <div class="post-img">
                  <div class="img"> <img src="images/1.jpg" alt="1"> </div>
                </div>
                <div class="cont">
                  <div class="info"> <a href="#0">06 Aug 2018</a> <a href="#0">by Anya Stark</a> </div>
                  <h6><a href="#0">This Simple Yet Powerful Weekly Planning Routine Puts You in Control</a></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the dummy text ever</p>
                </div>
              </div>
             <div class="item" >
                <div class="post-img">
                  <div class="img"> <img src="images/2.jpg" alt="1"> </div>
                </div>
                <div class="cont">
                  <div class="info"> <a href="#0">06 Aug 2018</a> <a href="#0">by Anya Stark</a> </div>
                  <h6><a href="#0">This Simple Yet Powerful Weekly Planning Routine Puts You in Control</a></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the dummy text ever</p>
                </div>
              </div>
          
        </div>
      </section>
      
     
      
    </div>
    </body>